<template>
    <!-- <div class="full-height"> -->
    <div
        class="re-input"
        :class="{
            'self-end': !$slots.label && !compLabel,
            [`re-${props.size}`]: props.size,
            'no-bottom-slots': props.noBottomSlots,
        }"
    >
        <template v-if="$slots.label || compLabel">
            <div
                class="label all-pointer-events row justify-between"
                :class="{
                    'label--stepper': props.stepper,
                }"
            >
                <slot name="label" v-bind="$slots['label'] ?? {}">
                    <p class="row no-wrap">
                        {{ compLabel !== true ? compLabel : '&nbsp;' }}
                        <re-tooltip v-if="tooltip" :label="tooltip" />
                    </p>
                </slot>
                <re-icon
                    v-if="props.showWarningIcon"
                    class="self-baseline"
                    color="warning"
                    name="mdi-alert-octagon"
                    size="sm"
                />
            </div>
        </template>
        <q-input
            ref="inputRef"
            v-model="vModelInput"
            outlined
            :dense="['md', 'sm', 'xs'].includes(props.size)"
            :class="{
                'input-embed-button': 'embed' in $slots,
                'left-right-buttons': props.stepper && !$attrs.readonly && !$attrs.disable,
                // 'bg-white': true,
            }"
            :style="{ width: props.width }"
            :input-style="`min-width: 2em; ${stepper ? 'text-align: center' : ''}`"
            :input-class="`re-${props.size} text-dark`"
            :type="props.type !== 'number' ? props.type : 'text'"
            v-bind="$attrs"
            :rules="[...autoRules, ...(props.rules || [])]"
            :name="props.name"
            :hide-bottom-space="props.hideBottomSpace"
            @update:model-value="interactedWith = true"
            @keydown="props.type == 'number' ? handleKeydown($event) : undefined"
            @keyup="props.type == 'number' ? handleKeyup($event) : undefined"
            @blur="handleBlur($event)"
        >
            <template v-if="props.stepper && !$attrs.readonly && !$attrs.disable" #prepend>
                <re-button
                    tabindex="-1"
                    icon="mdi-minus"
                    class="btn-stepper-left"
                    :size="props.size"
                    :disable="
                        $attrs.readonly ||
                        $attrs.disable ||
                        (props.min !== undefined && vModelInput <= props.min)
                    "
                    flat
                    @click="decrement"
                />
            </template>
            <template #append>
                <slot name="append">
                    <span v-if="$attrs.unit" :class="`re-${props.size}`">{{ $attrs.unit }}</span>
                </slot>
                <re-icon
                    v-if="props.showValidationIcon && hasError === false"
                    color="positive"
                    rounded-icon
                    :class="`re-${props.size}`"
                />
                <re-icon
                    v-if="props.showWarningIcon && !$slots.label && !compLabel"
                    color="warning"
                    rounded-icon
                    name="mdi-exclamation-thick"
                    :class="`re-${props.size}`"
                />
                <re-button
                    v-if="props.stepper && !$attrs.readonly && !$attrs.disable"
                    tabindex="-1"
                    icon="mdi-plus"
                    class="btn-stepper-right"
                    :size="props.size"
                    :disable="props.max !== undefined && vModelInput >= props.max"
                    flat
                    @click="increment"
                />
                <slot name="embed"></slot>
            </template>
            <template #after>
                <div v-if="tooltip && !compLabel" class="q-mr-sm">
                    <re-tooltip :label="props.tooltip" no-padding />
                </div>
                <slot name="after"></slot>
            </template>
            <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
                <slot
                    v-if="!['label', 'append', 'embed', 'after'].includes(slotName)"
                    :name="slotName"
                    v-bind="slotData || {}"
                ></slot>
            </template>
        </q-input>
    </div>
</template>
<script setup>
import { computed, ref, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import reRules from '@/shared/plugins/rules'

const { t, te } = useI18n()

var [vModelIn, vModelInModifiers] = defineModel({ type: [String, Number] })
const emit = defineEmits(['input'])

const props = defineProps({
    showValidationIcon: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'lg',
    },
    tooltip: {
        type: String,
        default: null,
    },
    label: {
        type: [String, Boolean],
        default: null,
    },
    type: {
        type: String,
        default: 'text',
    },
    required: {
        type: Boolean,
        default: false,
    },
    // Define the model from a curRequest key:
    fieldKey: {
        type: String,
        default: undefined,
    },
    // curRequest reactive options:
    reactiveOptions: {
        type: Object,
        default: () => {},
    },
    min: {
        type: Number,
        default: undefined,
    },
    max: {
        type: Number,
        default: undefined,
    },
    width: {
        type: String,
        default: undefined,
    },
    name: {
        type: String,
        default: undefined,
    },
    stepper: {
        type: Boolean,
        default: undefined,
    },
    noBottomSlots: {
        type: Boolean,
        default: false,
    },
    hideBottomSpace: {
        type: Boolean,
        default: true,
    },
    rounded: {
        type: [Boolean, Number],
        default: false,
    },
    rules: {
        type: Array,
        default: () => [],
    },
    showWarningIcon: {
        type: Boolean,
        default: false,
    },
})

const inputRef = ref(null)
const interactedWith = ref(false)
const hasError = computed(() => (interactedWith.value ? inputRef.value?.hasError : undefined))
const validate = (val) => inputRef.value?.validate(val)
defineExpose({ hasError, validate, inputRef })

const roundValue = (val) => {
    if (props.rounded === true) {
        return parseFloat(val).toFixed()
    } else {
        return Math.round(parseFloat(val) / props.rounded) * props.rounded
    }
}

var vModel = props.fieldKey
    ? inject('curRequest').getReactive(props.fieldKey, props.reactiveOptions)
    : vModelIn

// Keep an inner vModel and only sync with the outer vModel if the value is valid:
var vModelInner = ref(vModel.value)

watch(vModel, (val) => {
    vModelInner.value = val
    emit('input', val)
})

const vModelInput = computed({
    get: () => {
        if (!interactedWith.value && props.rounded && vModelInner.value) return roundValue(vModelInner.value)
        return vModelInner.value
    },
    set: (val) => {
        vModelInner.value = val
        if (validate(val)) {
            vModel.value = val
        }
    },
})

const autoRules = computed(() => {
    const retRules = []
    if (props.type === 'number' || vModelInModifiers.number) retRules.push(reRules.numeric)
    if (props.type === 'email') retRules.push(reRules.email)
    if (props.required) retRules.push(reRules.required)
    if (props.min !== undefined) retRules.push(reRules.minValue(props.min))
    if (props.max !== undefined) retRules.push(reRules.maxValue(props.max))
    return retRules
})

const compLabel = computed(() =>
    props.label ? props.label : props.fieldKey && te(props.fieldKey) ? t(props.fieldKey) : null
)

const increment = () => {
    if (vModelInput.value == null || isNaN(vModelInput.value)) {
        vModelInput.value = 0
    } else {
        if (props.max === undefined || vModelInput.value < props.max) {
            vModelInput.value++
        }
    }
}

const decrement = () => {
    if (vModelInput.value == null || isNaN(vModelInput.value)) {
        vModelInput.value = 0
    } else {
        if (props.min === undefined || vModelInput.value > props.min) {
            vModelInput.value--
        }
    }
}

const handleKeydown = (event) => {
    if (event.key === 'ArrowDown') {
        decrement()
    }
}
const handleKeyup = (event) => {
    if (event.key === 'ArrowUp') {
        increment()
    }
}

const handleBlur = (_evt) => {
    if (vModelIn.value && props.rounded) vModelIn.value = roundValue(vModelIn.value)
}
</script>
<style lang="scss" scoped>
.re-input {
    &.no-bottom-slots {
        :deep(.q-field__bottom) {
            display: none;
        }
    }
    :deep(.q-field__control) {
        background-color: white;
        // height: 52px;
        height: 2.858em !important;
    }

    :deep(.q-field--dense .q-field__control) {
        height: 2.858em !important;
        min-height: 2.858em !important;
    }

    :deep(.q-textarea .q-field__control) {
        height: auto !important;
    }

    :deep(.q-field__marginal) {
        height: inherit;
    }

    // TODO: check whether this is still needed:
    :deep(textarea) {
        line-height: normal;
        padding-bottom: 0.75em;
    }

    .input-embed-button {
        :deep(.q-field__control) {
            padding-right: 0 !important;
            overflow: hidden;
        }
    }

    .left-right-buttons {
        max-width: 18em;
        min-width: 10em;
        :deep(.q-field__control) {
            padding: 0 !important;
            // overflow: hidden;
        }
        :deep(.q-btn) {
            width: 2.858em;
        }
    }

    .btn-stepper-left :deep(.q-btn) {
        border-radius: $generic-border-radius 0 0 $generic-border-radius;
    }

    .btn-stepper-right :deep(.q-btn) {
        border-radius: 0 $generic-border-radius $generic-border-radius 0;
    }

    .label {
        max-height: calc(1.2em + 8px);
        height: calc(1.2em + 8px);
        &--stepper {
            max-width: 18em;
        }
        p {
            font-size: 1em;
            text-wrap: nowrap;
            line-height: 1.2em;
            color: $secondary;
            font-weight: 500;
        }
    }

    :deep(input::placeholder),
    :deep(textarea::placeholder) {
        color: $secondary;
    }

    :deep(.q-field--readonly input::placeholder),
    :deep(.q-field--readonly textarea::placeholder) {
        color: transparent;
    }
}

body.screen--xs .re-input .left-right-buttons {
    max-width: inherit;
}
</style>
